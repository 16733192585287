class Toner
	constructor : () ->
		@reverb = new Tone.Reverb().toDestination()
		@delay = new Tone.FeedbackDelay("8t", 0.7).connect @reverb
		@radar = new Tone.Synth(oscillator : type : "sine").connect @delay
		@noise_env = new Tone.AmplitudeEnvelope(
			attack : 0.3
			decay : 0.4
			sustain : 1.0
			release : 8.4
		).toDestination()
		@freq_env = new Tone.FrequencyEnvelope({
			attack: 0.2,
			baseFrequency: "A#4",
			octaves: 1
		}).connect(@radar.frequency)
		@noise_filter = new Tone.AutoFilter({
			frequency: "8n",
			baseFrequency: 200,
			octaves: 6
			type : "square3"
			filter : 
				type : "bandpass"
				Q : 3
		}).connect @noise_env#.toDestination().start()
		@noise = new Tone.Noise("pink").start().connect @noise_filter
		# @noise_filter = new Tone.Filter(1200, "lowpass").toDestination()
		# @noise = new Tone.NoiseSynth().connect @noise_filter
		# @radar_loop = new Tone.Loop(((time) => @radar.triggerAttackRelease("C5", "8n")), "1n")

	start : () ->
		if Tone.Transport.state isnt "started"
			Tone.context.resume()
			# Tone.Transport.start()
			# @noise.triggerAttack()
	trigger_radar : () ->
		@now = Tone.now();
		try
			console.log @now
			@radar.triggerAttackRelease("C3", "32n", @now);
		catch e
			console.log e
		@freq_env.triggerAttack();


###

topograph 
	sand flow / underwater
flow 
	wind
tile
	pluck in out
goal
	radar ping
spawn
	freq
clouds
	spray 
game
	start
	spawn
	die
	finish
	end win

step menu

###